var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', _vm._l(_vm.menus, function (menu, index) {
    return _c('c-flex', {
      key: index,
      attrs: {
        "w": "100%",
        "align-items": "flex-start",
        "p": "10px",
        "mt": index !== 0 ? ['20px'] : [],
        "bg": "white",
        "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.1)",
        "border-radius": "8px"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex": "1",
        "flex-dir": "column"
      }
    }, [_c('c-flex', {
      attrs: {
        "flex": "1",
        "align-items": "center"
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-style": "normal",
        "font-weight": "700",
        "line-height": "24px"
      }
    }, [_vm._v(" " + _vm._s(menu.title) + " ")]), _c('c-flex', {
      attrs: {
        "ml": "10px",
        "align-items": "center"
      }
    }, [_c('c-image', {
      attrs: {
        "h": "24px",
        "w": "24px",
        "src": require('@/assets/icon-jam-grey.svg'),
        "alt": "icon waktu"
      }
    }), _c('c-text', {
      attrs: {
        "ml": "5px",
        "font-size": "14px",
        "font-weight": "400",
        "line-height": "21px"
      }
    }, [_vm._v(" " + _vm._s(menu.filterTime) + " ")])], 1)], 1), menu.meals.length > 0 ? _c('c-list', {
      attrs: {
        "style-type": "disc"
      }
    }, _vm._l(menu.meals, function (item, index_) {
      return _c('c-list-item', {
        key: index_
      }, [_vm._v(" " + _vm._s(item.text) + " ")]);
    }), 1) : _vm._e()], 1), _vm.withSelectMenuBtn ? _c('FlatButton', {
      attrs: {
        "variant": "ghost",
        "h": "auto",
        "w": "auto",
        "min-w": "auto",
        "m": "0",
        "p": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('select-menu', menu);
        }
      }
    }, [_c('c-image', {
      attrs: {
        "h": "24px",
        "w": "24px",
        "src": require('@/assets/icon-chevron-right.svg'),
        "alt": "icon arrow"
      }
    })], 1) : _vm._e()], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }